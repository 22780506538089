<script setup lang="ts">
import { useRoute } from 'vue-router'
import FormService from '@/services/FormService'
import { identityStore } from '~/stores/identityStore'

defineEmits(['close'])
const route = useRoute()
const send = ref<boolean>(false)
const errorText = ref<string>('')

const iStore = identityStore()

interface FormType {
  feedback: string
  acceptedToBeContacted: boolean
  email?: string
}

const submit = async (form: FormType) => {
  try {
    errorText.value = ''

    const { feedback, acceptedToBeContacted, email } = form
    const { loggedIn, email: UserEmail, firstName, lastName } = iStore

    const data = {
      feedback,
      acceptedToBeContacted,
      email,
      path: route.fullPath,
      date: new Date().toISOString(),
      user: loggedIn
        ? {
          email: UserEmail,
          name: firstName,
          id: lastName,
        }
        : undefined,
    }

    await FormService.postForm(data)
    send.value = true
  }
  catch (_err) {
    errorText.value = 'Er is iets misgegaan bij het versturen van je feedback. Probeer het later nog eens.'
  }
}
</script>

<template>
  <div v-if="!send" data-cy="feedback-form">
    <slot />
    <FormKit
      v-slot="{ value }"
      type="form"
      data-cy="send-feedback"
      submit-label="Feedback versturen"
      @submit="submit"
    >
      <FormKit
        type="textarea"
        data-cy="feedback-text-field"
        name="feedback"
        label="Heb je suggesties voor ons?"
        rows="3"
        validation="required"
      />
      <FormKit
        type="checkbox"
        name="acceptedToBeContacted"
        data-cy="acceptedToBeContacted"
        decorator-icon="check"
        label="Jullie mogen contact met mij opnemen naar aanleiding van deze aanvraag"
      />
      <FormKit
        v-if="value?.acceptedToBeContacted"
        data-cy="emailForContact"
        name="email"
        :value="iStore.email"
        label="E-mail"
        validation="email"
      />
    </FormKit>
    <BaseMessage v-if="errorText" data-cy="feedback-error" status="error">
      {{ errorText }}
    </BaseMessage>
  </div>
  <div v-else class="flex h-full w-80 flex-col px-4">
    <BaseDoppie name="1 duim omhoog" />
    <BaseTitle level="h4" class="my-3 text-green">
      Jouw feedback is ontvangen
    </BaseTitle>
    <div class="prose">
      <p data-cy="feedback-success">
        Bedankt! Dankzij jou kunnen wij ons platform blijven verbeteren.
      </p>
    </div>
  </div>
</template>
